import React, { Component } from "react";

export default class Footer2 extends Component {
  render() {
    return (
      <footer className="footer2">
        <div className="row" style={{"paddingBottom" : "1rem"}}>
          <div className="col-md-1 col-lg-1" />
          <div className="col-md-3 col-lg-4">
            <p className="footer2-logo"></p>
          </div>
          <div className="col-md-8 col-lg-7">
            <p className="copyright">
              {" "}
              &copy; HiT Nano 2021, All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
