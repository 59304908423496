import React, { Component } from "react";
import ScrollBar from "./Scrollbar";

export default class ManageScrollBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollY: 0,
      scrollBarRate: 0
    };
    this.ScrollRateCalculation = this.ScrollRateCalculation.bind(this);
  }

  ScrollRateCalculation() {
    let innerHeight = window.innerHeight; //A
    let bodyElement = document.getElementById("rect"); //B1
    let rect = bodyElement.getBoundingClientRect(); //B2
    let heightIsHtml = rect.height; //B3
    let scrollMax = Math.ceil(heightIsHtml - innerHeight); //C = B3 - A
    let scrollY = document.documentElement.scrollTop || document.body.scrollTop; //D
    let scrollRate = parseInt((scrollY / scrollMax) * 100, 10); //E = (D / C) *100
    this.setState({
      scrollY: scrollY,
      scrollBarRate: scrollRate
    });
  }

  componentDidMount() {
    this.ScrollRateCalculation();

    document.addEventListener("scroll", this.ScrollRateCalculation);
  }

  render() {
    return (
      <div>
        <ScrollBar
          className={this.props.className}
          width={this.state.scrollBarRate}
        />
      </div>
    );
  }
}
