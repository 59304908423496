import React, { Component } from "react";
import Navbar from "../Navbar";
import {Card} from "react-bootstrap";
import sbir from "../../img/SBIR.jpg";
import depofenergy from "../../img/depofenergy.jpg";
import cto from "../../img/CTO.PNG";
import njeda from "../../img/NJEDA.PNG";
import csit from "../../img/CSIT.PNG"
import bird from "../../img/bird.png"

export default class News extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <div>
                    <div className="fixed-top">
                        <Navbar
                            className1="notActive"
                            className2="notActive"
                            className3="notActive"
                            className4="notActive"
                            className5="notActive"
                            className6="activeNav"
                            className7="notActive"
                            className8="notActive"
                            type="noImage"
                            color="orange"
                        />
                    </div>
                </div>
                <div className="content-mats container-fluid content-background">
                    <Card body className="content-card content-card-no-banner">
                        <div className="row text-center">
                            <div className="col-2"/>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col">
                                        <p className="text-left" style={{fontSize: "40px"}}>News</p>
                                        <ul className="news-bullets">
                                            <li><b>September 2022</b> - HiT Nano is a winner of the Clean Tech Open Northeast finals: <a className="newsLink" href="https://www.cleantechopen.org/en/custom/blog/view/90760" target="_blank">https://www.cleantechopen.org/en/custom/blog/view/90760</a></li>
                                            <li><b>August 2022</b> - HiT Nano is awarded a Phase II SBIR grant funded by the Department of Energy Advanced Manufacturing office to develop materials for high-temperature thermochemical energy storage: <a className="newsLink" href="https://www.energy.gov/eere/amo/articles/advanced-manufacturing-office-announces-115-million-10-small-business-research" target="_blank">https://www.energy.gov/eere/amo/articles/advanced-manufacturing-office-announces-115-million-10-small-business-research</a></li>
                                            <li><b>August 2022</b> - HiT Nano is awarded a Clean Tech Seed Grant by the New Jersey Economic Development Authority Commission on Science, Innovation and Technology to develop advanced cathode materials for rechargeable sodium-ion batteries using HiT Nano’s manufacturing processes. <a className="newsLink" href="https://www.njeda.com/csit-awards-nearly-4-million-to-45-nj-startups-through-catalyst-and-clean-tech-seed-grant-programs/" target="_blank">https://www.njeda.com/csit-awards-nearly-4-million-to-45-nj-startups-through-catalyst-and-clean-tech-seed-grant-programs/</a></li>
                                            <li><b>June 2022</b> - HiT Nano is awarded a grant via the New Jersey Economic Development Authority's Commission on Science, Innovation and Technology to use advanced materials characterisation equipment in the Imaging and Analysis Center at Princeton University’s Princeton Institute of Materials. </li>
                                            <li><b>January 2022</b> - HiT Nano is awarded a SBIR/STTR Direct Financial Assistance Grant by the NJ EDA CSIT to support R&D activities on thermochemical energy storage</li>
                                            <li><b>September 2021</b> - HiT Nano is awarded a grant via the New Jersey Economic Development Authority's Commission on Science, Innovation and Technology to use advanced materials characterisation equipment in the Imaging and Analysis Center at Princeton University’s Princeton Institute of Materials. <a className="newsLink" href="https://www.njeda.com/csit-announces-initial-clean-tech-rd-program-awardees/" target="_blank">https://www.njeda.com/csit-announces-initial-clean-tech-rd-program-awardees/</a></li>
                                            <li><b>January 2021</b> - HiT Nano is awarded a grant funded by the US Department of Energy and Israel Ministry of Energy via the Binational Industrial Research and Development (BIRD) Energy program. We will partner with Tadiran Batteries and develop high-energy and fire-resistant AA Li-ion cells for industrial, agricultural and secondary automotive applications.  Link to the Department of Energy announcement can be found here: <a className="newsLink" href="https://www.energy.gov/articles/department-energy-announces-eight-new-projects-through-bird-energy-partnership-israel" target="_blank">https://www.energy.gov/articles/department-energy-announces-eight-new-projects-through-bird-energy-partnership-israel</a></li>
                                            <li><b>August 2020</b> - HiT Nano is awarded an SBIR grant funded by the Department of Energy Advanced Manufacturing office to develop materials for high-temperature thermochemical energy storage</li>
                                            <li><b>July 2019</b> - HiT Nano is awarded an SBIR grant funded by the Department of Energy Vehicle Technology office to work on the development of high-energy cathode materials</li>
                                            <li><b>July 2023</b> - HiT Nano is awarded the Pilot Clean Tech Demonstration Grant from the NJ EDA Commission on Science Innovation and Technology (CSIT). This grant will enable HiT Nano to scale its MACHT manufacturing technology and deliver high performance Li-ion and Na-ion cathode materials to customers.<a className="newsLink" href="https://www.njeda.gov/csit-awards-3-6m-through-pilot-clean-tech-demonstration-grant-program14-nj-clean-tech-startups-poised-to-receive-funding-to-test-validate-their-technologies/" target="_blank">https://www.njeda.gov/csit-awards-3-6m-through-pilot-clean-tech-demonstration-grant-program14-nj-clean-tech-startups-poised-to-receive-funding-to-test-validate-their-technologies/</a></li>
                                            <li><b>July 2023</b> - HiT Nano is awarded a Phase I SBIR grant from the US Department of Energy Vehicle Technology Office to develop high capacity, earth-abundant cathode materials for high energy densit sodium-ion batteries.<a className="newsLink" href="https://www.energy.gov/articles/doe-announces-72-million-small-business-research-and-development-grants" target="_blank">https://www.energy.gov/articles/doe-announces-72-million-small-business-research-and-development-grants</a></li>
                                            <li><b>September 2023</b> - HiT Nano is awarded a grant funded by the US Department of Energy Advanced Materials and Manufacturing Technlogies Office to develop, scale, and demonstrate our cathode active material MACHT aerosol manufacturing technology for domestic EV battery manufacutring.<a className="newsLink" href="https://www.energy.gov/eere/articles/us-department-energy-invests-61-million-fund-31-applied-research-development-and?" target="_blank">https://www.energy.gov/eere/articles/us-department-energy-invests-61-million-fund-31-applied-research-development-and?</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <img src={sbir} className="flexibleImage"/>
                                    </div>
                                    <div className="col-sm">
                                        <img src={depofenergy} className="flexibleImage"/>
                                    </div>
                                    <div className="col-sm">
                                        <img src={cto} className="flexibleImage"/>
                                    </div>
                                    <div className="col-sm">
                                        <img src={njeda} className="flexibleImage"/>
                                    </div>
                                    <div className="col-sm">
                                        <img src={csit} className="flexibleImage"/>
                                    </div>
                                    <div className="col-sm">
                                        <img src={bird} className="flexibleImage"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"/>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}