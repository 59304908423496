import React, { Component } from "react";
import ResponsiveMenu from "react-responsive-navbar";
import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { navicon } from "react-icons-kit/fa/navicon";

import Navbar from "../Navbar";
import Newsletter from "../Newsletter";
import Footer from "../Footer";
import Footer2 from "../Footer2";

export default class Subscription extends Component {
  render() {
    return (
      <div>
        <section className="bgimage2">
          <div className="container-fluid container-margin">
            <div className="fixed-top">
              <Navbar
                className1="notActive"
                className2="notActive"
                className3="notActive"
                className4="notActive"
                className5="notActive"
                className6="notActive"
                className7="notActive"
                className8="notActive"
                className9="notActive"
                color="white"
              />
            </div>
            <div className="hero-text">
              <p
                className="bigText"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Thank you
              </p>
              <p className="subText">Your message has been sent.</p>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row text-center">
            <div className="col">
              <p className="subscriptionText">
                Thank you for contacting us! A confirmation email was sent to
                you; we will get back to you at our earliest convenience.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid">
          <Newsletter />
        </div> */}
        <Footer2 />
      </div>
    );
  }
}
