import React, { Component } from "react";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";

const PageLink = Scroll.Link;

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer1">
        <div className="row">
          <div className="col-sm-5">
            <p className="logo">HIT NANO</p>
            <div className="addr">
              <p> 303A College Rd E</p>
              <p>Princeton, NJ, 08540</p>
              <p>Email: jshan@hitnanoinc.com</p>
            </div>
          </div>
          <div className="col-sm-1 vertLine" />

          <div className="col-sm-4">
            <p className="footer-text">The future of energy storage is here.</p>
            <div>
              <ul className="footer-links">
                <Link style={{ color: "orange" }} to="/">
                  <li>home</li>
                </Link>
                <li className="about-footer">
                  <PageLink
                    style={{ color: "orange" }}
                    to="about"
                    smooth={true}
                  >
                    about
                  </PageLink>
                </li>
                <li>
                  <Link style={{ color: "orange" }} to="contactus">
                    contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </footer>
    );
  }
}
