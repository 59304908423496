import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/layout/Home";
import Subscription from "./components/layout/Subscription";
import Vision from "./components/layout/Vision";
import history from "./history";
import ContactUs from "./components/layout/ContactUs";
import Service from "./components/layout/Service";
import Contacted from "./components/layout/Contacted";
import ScrollToTop from "./components/ScrollToTop";
import Thermal from "./components/images/Images";
import { Optical } from "./components/images/Images";
import Employment from "./components/layout/Employment";
import Materials from "./components/layout/Materials";
import News from "./components/layout/News";
import Technology from "./components/layout/Technology";
import Publications from "./components/layout/Publications";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <ScrollToTop>
            <Route exact path="/" component={Home} />
            <Route exact path="/subscribed" component={Subscription} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/contact-confirmation" component={Contacted} />
            <Route exact path="/thermal" component={Thermal} />
            <Route exact path="/optical" component={Optical} />
            <Route exact path="/vision" component={Vision} />
            <Route exact path="/employment" component={Employment}/>
            <Route path="/materials/:material" render={props => <Materials {...props}/>}/>
            <Route exact path="/news" component={News}/>
            <Route exact path="/publications" component={Publications}/>
            <Route exact path="/service" component={Service}/>
            <Route exact path="/technology" component={Technology}/>
          </ScrollToTop>
        </div>
      </Router>
    );
  }
}

export default App;
