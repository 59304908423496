import React, { Component } from "react";
import Navbar from "../Navbar";
import {Card} from "react-bootstrap";
import sbir from "../../img/SBIR.jpg";
import depofenergy from "../../img/depofenergy.jpg";
import cto from "../../img/CTO.PNG";
import njeda from "../../img/NJEDA.PNG";
import csit from "../../img/CSIT.PNG"
import bird from "../../img/bird.png"
import technologymain from "../../img/technologymain.PNG"

export default class Technology extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <div>
                    <div className="fixed-top">
                        <Navbar
                            className1="notActive"
                            className2="notActive"
                            className3="notActive"
                            className4="notActive"
                            className5="notActive"
                            className6="activeNav"
                            className7="notActive"
                            className8="notActive"
                            type="noImage"
                            color="orange"
                        />
                    </div>
                </div>
                <div className="content-mats container-fluid content-background">
                    <Card body className="content-card content-card-no-banner">
                        <div className="row text-center">
                            <div className="col-2"/>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col">
                                        <p className="text-left" style={{fontSize: "40px"}}>Technology</p>
                                        <p className="text-left" style={{fontSize: "20px"}}>Advanced cathode manufacturing technologies that reduce chemical input, dramatically shorten calcination times, reduce the number of processing steps, and use less energy than conventional cathode manufacturing processes, significantly lowering the manufacturing costs of cathode materials. Our in-situ coating technology allows high rate performance and increased cycling stability. These technologies are patented, peer-reviewed, funded by the US Department of Energy, and developed in conjunction with US National Labs and leading universities – further information is available upon request.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <img src={technologymain} className="flexibleImage"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"/>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}