import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import { Redirect } from "react-router";
import history from "../history";

class Newsletter extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      errors: {},
      redirect: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const newNewsletteruser = {
      name: this.state.name,
      email: this.state.email
    };

    axios
      .post("api/newsletter/", newNewsletteruser)
      .then(res => {
        history.push("/subscribed");
        this.setState({ redirect: true });
      })
      .catch(err => this.setState({ errors: err.response.data }));
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/subscribed" />;
    }

    const { errors } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <div className="row newsletter-container text-center">
          <div className="col-md-1 col-lg-1" />

          <div className="col-md-3 col-lg-3 text-center">
            <div className="form-group">
              <input
                type="text"
                className={classnames("form-control inputBox", {
                  "is-invalid": errors.name
                })}
                placeholder="Name"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-md-3 col-lg-4 text-center">
            <input
              type="email"
              className={classnames("form-control inputBox inputEmail", {
                "is-invalid": errors.email
              })}
              placeholder="Email Address"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>
          <div className="col-md-auto col-lg-auto text-center">
            <div className="outer">
              <button
                type="submit"
                className="btn btn-secondary btn-responsive btn-lg btn-block"
              >
                {" "}
                <p>Subscribe to Newsletter</p>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Newsletter;
