import React, { Component } from "react";
import Navbar from "../Navbar";
import {Tabs, Tab, Card} from "react-bootstrap"
import batterymaterials from "../../img/battery-page-image.png"
import thermal from "../../img/thermo-page-image.png"
import optical from "../../img/optical.png"

export default class Materials extends Component {
    constructor() {
        super();
    }

    render() {
        const {material} = this.props.match.params;
        return (
            <div>
                <div>
                    <div className="fixed-top">
                        <Navbar
                            className1="notActive"
                            className2="notActive"
                            className3="notActive"
                            className4="notActive"
                            className5="activeNav"
                            className6="notActive"
                            className7="notActive"
                            className8="notActive"
                            className9="notActive"
                            type="noImage"
                            color="orange"
                        />
                    </div>
                </div>
                <div className="content-mats container-fluid content-background">
                    <Card body className="content-card content-card-no-banner" style={{marginTop:"1rem"}}>

                
                        <div className="row text-center">
                            <div className="col">
                                
                                    <Tabs defaultActiveKey={material} id="uncontrolled-tab-example">
                                        <Tab eventKey="battery" title="High-Energy Li-ion Battery Materials">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="mat-header">High-Energy Li-ion Battery Materials</p>
                                                    <img src={batterymaterials} className="flexibleImage"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="thermo" title="Thermochemical Energy Storage">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="mat-header">Thermochemical Energy Storage and CO<sub>2</sub> Capture</p>
                                                    <p className="mat-sub">Enabling efficient concentrated solar power generation and SCO<sub>2</sub> cycle</p>
                                                    <img src={thermal} className="flexibleImage"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="optical" title="Optical Imaging/Remote Sensing">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="mat-header">Rare-earth doped nanophospors for imaging</p>
                                                    <img src={optical} className="flexibleImage"/>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}