import React, { Component } from "react";
import Navbar from "../Navbar";
import {Tabs, Tab, Card, Row, Col, Nav} from "react-bootstrap";
import pub1controlled from "../../img/pub1-controlled.png"
import pub2flame from "../../img/pub2-flame.png"
import pub3promoting from "../../img/pub3-promoting.png"

export default class Publications extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <div>
                    <div className="fixed-top">
                        <Navbar
                            className1="notActive"
                            className2="notActive"
                            className3="notActive"
                            className4="notActive"
                            className5="notActive"
                            className6="notActive"
                            className7="activeNav"
                            className8="notActive"
                            className9="notActive"
                            type="noImage"
                            color="orange"
                        />
                    </div>
                </div>
                <div className="content-mats container-fluid content-background">
                    <Card body className="content-card content-card-no-banner">
                        <div className="row text-center">
                            <div class="col">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col sm={6}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Controlled Dy-doping to nickel-rich cathode materials in high temperature aerosol synthesis. <br/><a href="https://doi.org/10.1016/j.proci.2020.06.332" target="_blank">DOI: https://doi.org/10.1016/j.proci.2020.06.332</a></Nav.Link>
                                                
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Flame Aerosol Synthesis and Electrochemical Characterization of Ni-Rich Layered Cathode Materials for Li-Ion Batteries. <br/> <a href="https://doi.org/10.1021/acsaem.8b01892" target="_blank">DOI: https://doi.org/10.1021/acsaem.8b01892</a></Nav.Link>
                                        
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Promoting Si-graphite composite anodes with SWCNT additives for half and NCM811 full lithium ion batteries and assessment criteria from an industrial perspective. <br/> <a href="https://doi.org/10.1007/s11708-019-0650-y" target="_blank">DOI: https://doi.org/10.1007/s11708-019-0650-y</a></Nav.Link>
                                                
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={6}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Row>
                                                    <Col>
                                                        <a href="https://doi.org/10.1016/j.proci.2020.06.332" target="_blank">DOI: https://doi.org/10.1016/j.proci.2020.06.332</a>
                                                        
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <img className="flexibleImage" src={pub1controlled} />
                                                    </Col>
                                                </Row>
                                                
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Row>
                                                    <Col>
                                                        <a href="https://doi.org/10.1021/acsaem.8b01892" target="_blank">DOI: https://doi.org/10.1021/acsaem.8b01892</a>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <img className="flexibleImage" src={pub2flame} />
                                                    </Col>
                                                </Row>
                                                
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Row>
                                                    <Col>
                                                        <a href="https://doi.org/10.1007/s11708-019-0650-y" target="_blank">DOI: https://doi.org/10.1007/s11708-019-0650-y</a>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <img className="flexibleImage" src={pub3promoting} />
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}