import React, { Component } from "react";
import Navbar from "../Navbar";

import jobAdvert from "../../files/jobAdvert.pdf";

export default class Employment extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>

            
                <div>
                    <div className="fixed-top">
                        <Navbar
                            className1="notActive"
                            className2="notActive"
                            className3="notActive"
                            className4="activeNav"
                            className5="notActive"
                            className6="notActive"
                            className7="notActive"
                            className8="notActive"
                            className9="notActive"
                            type="noImage"
                            color="orange"
                        />
                    </div>
                </div>
                <div>
                    <embed src={jobAdvert} width="100%" height="1500px"/>

                </div>
            </div>
        )
    }
}