import React, { Component } from "react";

export default class Thermal extends Component {
  render() {
    return (
      <div>
        <img
          className="pictureLink"
          src={require("../../img/thermal.png")}
          alt="thermal chemical energy storage"
        />
      </div>
    );
  }
}

export class Optical extends Component {
  render() {
    return (
      <div>
        <img
          className="pictureLink"
          src={require("../../img/optical.png")}
          alt="optical imaging"
        />
      </div>
    );
  }
}
