import React, { Component } from "react";
import { Icon } from "react-icons-kit";
import { threeHorizontal } from "react-icons-kit/entypo/threeHorizontal";
import { arrows_circle_down } from "react-icons-kit/linea/arrows_circle_down";
import Scroll from "react-scroll";
import { Link } from "react-router-dom";

import Navbar from "../Navbar";
import Newsletter from "../Newsletter";
import Footer from "../Footer";
import Footer2 from "../Footer2";

import lightning2 from "../../img/lightning2.png"
import orangecoins256 from "../../img/orange-coins-256.png"
import leaf from "../../img/leaf.jpg"
import sbir from "../../img/sbir.svg"
import energy from "../../img/energy.png"
import technologymain from "../../img/technologymain.PNG"
import car from "../../img/car.jpg"
import cto from "../../img/CTO.PNG";
import njeda from "../../img/NJEDA.PNG";
import csit from "../../img/CSIT.PNG"
import bird from "../../img/bird.png"

const PageLink = Scroll.Link;

class Home extends Component {
  render() {
    return (
      <div>
        <section className="bgimage">
          <div className="container-fluid">
            <div className="fixed-top">
              <Navbar
                className1="activeNav"
                className2="notActive"
                className3="notActive"
                className4="notActive"
                className5="notActive"
                className6="notActive"
                className7="notActive"
                className8="notActive"
                className9="notActive"
                color="white"
              />
            </div>
            <div className="hero-text">
              <p className="bigText">Rethink energy storage.</p>
              <p className="subText">
                Advanced materials for energy storage, manufactured using a completely different particle technology.
              </p>
              <button className="heroButton">
                <PageLink to="testclass" smooth={true}>
                  LEARN MORE
                </PageLink>
              </button>
            </div>
            <div className="down-button">
              <PageLink to="testclass" smooth={true}>
                <div className="arrow-down" style={{ color: "white" }}>
                  <Icon icon={arrows_circle_down} size={40} />
                </div>
              </PageLink>
            </div>
          </div>
        </section>
        <div className="container-fluid testclass">
          <div className="container">
            {/* <div className="row text-center">
              <div className="col">
                <p className="high-temp"> High Temperature Nano </p>
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-4">
                <p className="about-header"> What we do </p>
                <div className="threedots" style={{ color: "orange" }}>
                  <Icon icon={threeHorizontal} size={120} />
                </div>
              </div>
              <div className="col-sm-8">
                <p className="about-text">
                  HiT Nano Inc. develops next generation low cost and high performance Li-ion batteries and energy storage materials using sustainable manufacturing technologies such as MACHT (Micro-Aerosol Controlled High Temperature processing).
                  The company was founded in 2018 by Princeton University scientists. HiT Nano Inc. manufactures industry-leading high energy{" "} 
                  <Link
                    className="pictureLinkHome"
                    to="/materials/battery"
                  >cathode and anode</Link> materials as well as materials for{" "}
                  <Link
                    className="pictureLinkHome"
                    to="/materials/thermo"
                  >
                    thermochemical energy storage
                  </Link>
                  ,{" "}
                  <Link
                    className="pictureLinkHome"
                    to="/materials/optical"
                  >
                    optical imaging/remote sensing
                  </Link>
                  ,  and catalysis alongside innovative processes for chemical synthesis.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <div className="col-sm">
              <p className="infoHeader">The Solution for Low Carbon Energy</p>
              <p className="subHead">
                {" "}
                Transforming devices in the world around you.{" "}
              </p>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-sm-4">
              <div className="icon-info" style={{ color: "orange" }}>
                <img
                  className="lightning"
                  src={lightning2}
                  alt="lightning"
                  style={{ height: "140px" }}
                />
              </div>
              <p className="text-header">High Performance</p>
              <p className="icon-text">
                We develop industry-leading, high performance rechargeable battery materials (Li and Na-ion) with superior high-rate performance and cycling stability
              </p>
            </div>
            <div className="col-sm-4">
              <div className="icon-info" style={{ color: "orange" }}>
                <img
                  src={orangecoins256}
                  alt="coins"
                  style={{ height: "125px" }}
                />
              </div>
              <p className="text-header">Low Cost</p>
              <p className="icon-text">
                At HiT Nano, we develop low-cost manufacturing routes that reduce the price of energy storage
              </p>
            </div>
            <div className="col-sm-4">
              <div className="icon-info" style={{ color: "orange" }}>
                <img
                  src={leaf}
                  alt="leaf"
                  style={{ height: "125px" }}
                />
              </div>
              <p className="text-header">
                {/* <PageLink to="macht-hidden" smooth={true}> */}
                  Sustainability
                {/* </PageLink> */}
              </p>

              <p className="icon-text">
                We develop manufacturing technologies that reduce chemical and energy input, CO2 emissions, and can utilise recycled battery materials
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid car-info">
          <div className="container">
            <div className="row car-row">
              <div className="col-sm-12 col-md-12 col-lg-6 carBackground">
                <img src={car} alt="car" id="car" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 carText">
                <p className="car-header">Transforming Rechargeable Batteries with Advanced Manufacturing</p>
                <p>
                The cathode in a battery is a major cost driver for batteries and EVs. Cathode production is traditionally done through multi-step, chemically-intensive co-precipitation which is lengthy and costly. HiT Nano has a simple method to generate cathode materials at low cost and reduced environmental impact. All of this is possible with HiT Nano's high temperature synthesis methods.
                </p>
                <button className="car-button">
                  <Link to="/contactus" className="car-link">
                    Questions?
                  </Link>
                </button>
                <p name="macht-hidden" className="macht-hidden">
                  Hidden
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center macht-banner">
          <div className="col-sm-2" />
            <div className="col-sm-8">
              <p className="macht-header"> Our Core Technology </p>
            </div>
          <div className="col-sm-2" />
        </div>
        <div className="row text-center">
          <div className="col-sm-2" />
            <div className="col-sm">
                <img
                  src={technologymain}
                  alt="technologymain"
                  className="flexibleImage"
                />
            </div>
          <div className="col-sm-2" />
        </div>
        <div className="row text-center partner-banner">
          <div className="col-sm-2" />
          <div className="col-sm-8">
            <div  className="row text-center">
              <div className="col">
                <p className="macht-header"> Financial Support </p>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md">
                  <img
                    src={sbir}
                    alt="SBIR"
                    className="flexibleImage"
                    id="sbir"
                  />
              </div>
              <div className="col-md">
                  <img src={njeda} className="flexibleImage"/>
              </div>
              <div className="col-md">
                  <img
                    src={energy}
                    alt="SBIR"
                    className="flexibleImage"
                    id="doe"
                  />
              </div>
              <div className="col-sm">
                  <img src={cto} className="flexibleImage"/>
              </div>
              <div className="col-sm">
                  <img src={csit} className="flexibleImage"/>
              </div>
              <div className="col-sm">
                  <img src={bird} className="flexibleImage"/>
              </div>
            </div>
          </div>
          <div className="col-sm-2" />
        </div>

        {/*<div className="row text-center cord-image text-center">
          <div className="col-sm-3" />
          <div className="col-sm-6">
            <img
              src={require("../../img/cord.png")}
              alt="USB by Alena from the Noun Project"
              id="cord"
            />
            <p className="cordHeader">Stay connected</p>
            <p className="cordText">
              We hope to change the ways that you can stay connected and
              interact with the technologies around you. Questions are bound to
              come up. Connect with us today.
            </p>
            <button className="cord-button">
              <Link to="/contactus" className="cord-link">
                Contact Us
              </Link>
            </button>
          </div>
          <div className="col-sm-3" />
    </div> */}

        {/* <div class="container-fluid">
          <Newsletter />
        </div> */}
        <Footer2 />
      </div>
    );
  }
}

export default Home;
