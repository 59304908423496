import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ScrollBar extends Component {
  render() {
    const { width, height } = this.props;
    return (
      <div>
         <div
          className={this.props.className}
          id="hoge"
          style={{
            width: `${width}%`,
            height,
            background: "orange"
          }}
        />
      </div>
    );
  }
}
ScrollBar.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

ScrollBar.defaultProps = {
  height: 4,
  width: 0
};
