import React, { Component } from "react";
import Navbar from "../Navbar";
import Newsletter from "../Newsletter";
import Footer2 from "../Footer2";
import { Link } from "react-router-dom";
import {Card} from "react-bootstrap";
import vision from "../../img/vision.png"
import Scroll from "react-scroll";

const PageLink = Scroll.Link

export default class Vision extends Component {
  render() {
    return (
      <div>
        <section className="bgimage4">
          <div className="container-fluid">
            <div className="fixed-top">
              <Navbar
                className1="notActive"
                className2="notActive"
                className3="activeNav"
                className4="notActive"
                className5="notActive"
                className6="notActive"
                className7="notActive"
                className8="notActive"
                className9="notActive"
                color="white"
              />
            </div>
            <div className="hero-text2">
              <p className="bigText2">Our Vision</p>
              <p className="subText2">A low carbon future is within reach </p>
              <button className="heroButton">
                <PageLink to="scroll-point" smooth={true}>
                  LEARN MORE
                </PageLink>
              </button>
            </div>
          </div>
        </section>
        <div className="container-fluid content-background scroll-point">
            <Card body className="content-card" style={{marginTop: "2rem", marginBottom: "2rem"}}>
              <div className="row text-center">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                  <p className="vision-header">A Low Carbon Future</p>
                  <img
                    src={vision}
                    alt="vision-image"
                    id="vision"
                  />
                </div>
                <div classname="col-sm-2" />
              </div>
            </Card>

        </div>
        {/* <div className="container-fluid">
          <Newsletter />
        </div> */}
        <Footer2 />
      </div>
    );
  }
}
