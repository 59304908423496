import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import { Redirect } from "react-router";
import history from "../../history";

import Newsletter from "../Newsletter";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Footer2 from "../Footer2";

export default class ContactUs extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      errors: {},
      redirect: false,
      disabled: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      disabled: true,
    })

    const Contact = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    };

    axios
      .post("/api/contact/contactus", Contact)
      .then(res => {
        history.push("/contactus");
        this.setState({ redirect: true });
      })
      .catch(err => this.setState({ 
        errors: err.response.data,
        disabled: false
      }));
  }

  render() {
    const { errors } = this.state; // const errors = this.state.errors

    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/contact-confirmation" />;
    }

    return (
      <div>
        <section className="bgimage3">
          <div className="container-fluid">
            <div className="fixed-top">
              <Navbar
                className1="notActive"
                className2="activeNav"
                className3="notActive"
                className4="notActive"
                className5="notActive"
                className6="notActive"
                className7="notActive"
                className8="notActive"
                className9="notActive"
                color="orange"
              />
            </div>
            <div className="hero-text1">
              <p className="bigText1">Questions?</p>
              <p className="subText1">
                Contact us today. We'll get back to you shortly.
              </p>
            </div>
          </div>
        </section>
        <div className="container-fluid contact-details">
          <div className="container">
            {/* <div className="row text-center">
              <div className="col">
                <p className="preText">
                  {" "}
                  Let's build an energy future together.{" "}
                </p>
              </div>
    </div> */}
            <div className="row text-center">
              <div className="col-sm-6 addrCol">
                <div className="card address-card">
                  <div className="card-body contact-card">
                    <h1 className="addr-header"> Address: </h1> <br />
                    <p>1200 Florence Columbus Rd, Ste. 113</p>
                    <p>Bordentown NJ</p>
                    <p>08505</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card email-card">
                  <div className="card-body contact-card">
                    <h1 className="email-header"> Email: </h1> <br />
                    <p> cabram@hitnanoinc.com </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid form-container">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center contact-header">
                  Get in touch.
                </h1>
                <p className="lead text-center">
                  Please fill out the form below.
                </p>
                <form
                  noValidate
                  onSubmit={this.onSubmit}
                  className="contact-form"
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.name
                      })}
                      placeholder="Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.email
                      })}
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.subject
                      })}
                      placeholder="Subject"
                      name="subject"
                      value={this.state.subject}
                      onChange={this.onChange}
                    />
                    {errors.subject && (
                      <div className="invalid-feedback">{errors.subject}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <textarea
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.message
                      })}
                      id="message-box"
                      placeholder="Message"
                      name="message"
                      value={this.state.message}
                      onChange={this.onChange}
                    />
                    {errors.message && (
                      <div className="invalid-feedback">{errors.message}</div>
                    )}
                  </div>
                  <input
                    disabled={this.state.disabled}
                    type="submit"
                    value = {this.state.disabled ? "Loading" : "Submit"}
                    className="btn btn-info btn-block mt-4 btn-contact"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="container-fluid">
          <Newsletter />
        </div> */}
        <Footer2 />
      </div>
    );
  }
}
