import React, { Component } from "react";
import ResponsiveMenu from "react-responsive-navbar";
import { Icon } from "react-icons-kit";
import { navicon } from "react-icons-kit/fa/navicon";
import { Link } from "react-router-dom";
import ManageScrollBar from "./ManageScrollBar";
import logo from "../img/HiTNano-logo-2021.svg"

export default class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      className: "no-scroll",
      isTop: true,
      mode: false
    };
    this.toggleMode = this.toggleMode.bind(this);
  }

  componentDidMount() {
    if (this.props.type == "noImage") {
      this.setState({className: "scrolled"})
    } else {
      document.addEventListener("scroll", () => {
        const isTop = window.scrollY < 100;
        if (isTop !== this.state.isTop) {
          this.setState({ isTop });
          this.setState({ className: "scrolled" });
        }
        if (isTop) {
          this.setState({ className: "no-scroll" });
        }
      });

    }
  }

  toggleMode() {
    if (!this.state.mode) {
      this.setState({
        mode: true
      });
    } else if (this.state.mode) {
      this.setState({
        mode: false
      });
    }
  }

  render() {
    var scrollclass = "scrollbar";
    if (!this.state.mode) {
      scrollclass = "scrollbar";
    } else {
      scrollclass = "scrollbar-clicked";
    }

    return (
      <div>
        <ResponsiveMenu
          menuOpenButton={
            <div
              style={{
                color: "white",
                paddingTop: "20px",
                paddingBottom: "20px",
                transition: "0.5s"
              }}
            >
              <Icon onClick={this.toggleMode} icon={navicon} size={40} />
            </div>
          }
          menuCloseButton={
            <div
              style={{
                color: "orange",
                paddingTop: "20px",
                transition: "0.5s"
              }}
            >
              <Icon onClick={this.toggleMode} icon={navicon} size={40} />
            </div>
          }
          changeMenuOn="620px"
          largeMenuClassName={this.state.className}
          smallMenuClassName={this.state.className}
          menu={
            <div className="row navTop">
              <div className="col-4 leftNav">
                <Link style={{ textDecoration: "none" }} to="/">
                  {/* <p>HIT NANO</p> */}
                  <img src={logo} height="35px" style={{"marginTop" : "-2rem", "marginLeft" : "2rem"}}/>
                </Link>
              </div>
              <div className="col-8 rightNav">
                <ul className="navitems">
                  <Link to="/">
                    <li className={this.props.className1}> HOME </li>
                  </Link>
                  <Link to="/technology">
                    <li className={this.props.className9}> TECHNOLOGY </li>
                  </Link>
                  <Link to="/vision" hidden={true}>
                    <li className={this.props.className3}> VISION </li>
                  </Link>
                  <Link to="/materials/battery">
                    <li className={this.props.className5}> MATERIALS </li>
                  </Link>
                  <Link to="/news">
                    <li className={this.props.className6}> NEWS </li>
                  </Link>
                  <Link to="/publications" hidden={true}>
                    <li className={this.props.className7}> PUBLICATIONS </li>
                  </Link>
                  <Link to="/service" hidden={true}>
                    <li className={this.props.className8}> SERVICE </li>
                  </Link>
                  <Link to="/employment">
                    <li className={this.props.className4}> EMPLOYMENT </li>
                  </Link>
                  <Link className="rightMost" to="/contactus">
                    <li className={this.props.className2}> CONTACT </li>
                  </Link>
                </ul>
              </div>
            </div>
          }
        />
        {/* <ManageScrollBar className={scrollclass} /> */}
      </div>
    );
  }
}
