import React, { Component } from "react";
import Navbar from "../Navbar";
import {Tabs, Tab, Card} from "react-bootstrap"

import service from "../../img/service.png"

export default class Materials extends Component {
    constructor() {
        super();
    }

    render() {
        const {material} = this.props.match.params;
        return (
            <div>
                <div>
                    <div className="fixed-top">
                        <Navbar
                            className1="notActive"
                            className2="notActive"
                            className3="notActive"
                            className4="notActive"
                            className5="notActive"
                            className6="notActive"
                            className7="notActive"
                            className8="activeNav"
                            className9="notActive"
                            type="noImage"
                            color="orange"
                        />
                    </div>
                </div>
                <div className="content-mats container-fluid content-background">
                    <Card body className="content-card content-card-no-banner" style={{marginTop:"1rem"}}>

                
                        <div className="row text-center align-items-center">
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col">
                                        <span style={{width: "75%"}}>
                                            <p style={{fontSize: "18px"}}>We have extensive expertise in temperature measurements using thermographic phosphors for non-intrusive diagnostics on surfaces, in liquids, and in gases. </p>
                                            <br/>
                                            <p style={{fontSize: "18px"}}>We can supply benchmarked luminescent sensor particles and provide support for scientists implementing phosphor thermometry for fundamental and applied research. For details please contact us.</p>
                                        </span>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col">
                                    <img src={service} className="flexibleImage"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}